<template>
  <div>
    <!-- SECTION modal-ban-or-unban-booking -->
    <b-modal
      id="modal-ban-or-unban-booking"
      :title="bookingData.isBanned ? 'Xác nhận' : 'Chọn thời gian khóa'"
      title-class="font-medium-4 font-weight-bolder"
      centered
      size="md"
      @show="showHandle"
    >
      <template #modal-footer="{cancel}">
        <div class="d-flex justify-content-end w-100">
          <b-button
            size="md"
            variant="outline-secondary"
            class="center rounded-pill mr-2"
            @click="cancel()"
          >
            {{ $t('reservation.back') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-gradient border-right-0"
            pill
            @click="handleSubmit"
          >
            <div class="d-flex-center px-25">
              Xác nhận
            </div>
          </b-button>
        </div>
      </template>

      <div v-if="bookingData.isBanned">
        Bạn có chắc chắn muốn mở khóa ip {{ bookingData.ip }}
      </div>
      <b-form-group
        v-else
        class="mb-0"
      >
        <b-form-radio-group
          id="rebook-type-radios"
          v-model="dataAdd.times"
          :options="timeOptions"
          name="radios-stacked"
          stacked
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BButton,
  BFormGroup,
  BFormRadioGroup,
  BModal,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'

import { convertISODateTime, formatCurrency } from '@/@core/utils/filter'

import formValidation from '@core/comp-functions/forms/form-validation'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal,
    BFormGroup,
    BButton,
    BFormRadioGroup,
  },
  props: {
    bookingData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      unbanBooking,
      banBooking,
    } = useReservationHandle()

    const blankData = {
      times: 60,
    }

    const timeOptions = [
      {
        text: '1h',
        value: 60,
      },
      {
        text: '1 ngày',
        value: 1440,
      },
      {
        text: 'Vĩnh viễn',
        value: undefined,
      },
    ]

    const dataAdd = ref(cloneDeep(blankData))

    const resetData = () => {
      dataAdd.value = cloneDeep(blankData)
    }

    const { refFormObserver, getValidationState } = formValidation(resetData)

    function showHandle() {
      resetData()
    }

    async function handleSubmit() {
      this.$bvModal.show('modal-api-loading')
      if (props.bookingData.isBanned) {
        await unbanBooking(props.bookingData.id).catch(() => {})
      } else {
        await banBooking(props.bookingData.id, { times: dataAdd.value.times }).catch(() => {})
      }
      emit('refetch-data') // reload list reservation
      this.$bvModal.hide('modal-ban-or-unban-booking')
      this.$bvModal.hide('modal-api-loading')
    }

    return {
      dataAdd,
      showHandle,
      formatCurrency,
      handleSubmit,
      convertISODateTime,
      timeOptions,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss" scoped>
#rebook-type-radios::v-deep {
  .custom-control.custom-radio {
    margin-bottom: 10px;
    .custom-control-label {
      margin-top: -2px !important;
      font-size: 18px !important;
    }}
}
</style>
